import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import gsap from 'gsap/dist/gsap';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';

import designRushLogo from 'assets/image/designRushLogo.png';
import { COMPANY_VARIABLES } from 'common/constants/company';
import { IAnyType } from 'common/types/common';

import { Button } from '../Button';
import { Link } from '../Link/animated';

export const MenuModal = forwardRef(
  (
    { otherLanguage, otherLanguageSlug }: { otherLanguage?: string; otherLanguageSlug?: string },
    ref,
  ) => {
    const { t } = useTranslation('common');
    const [visible, setVisible] = useState(false);

    const el = useRef(null);

    const menu: any = t('menu.items', { returnObjects: true });
    const menuPrivacy: any = t('menuPrivacy.items', { returnObjects: true });

    const toggleMenu = () => {
      if (visible) {
        setVisible(prev => !prev);
        document.body.classList.remove('body-overflow-hidden');
        const q = gsap.utils.selector(el);

        gsap.timeline({ delay: 0 }).to(q(`#menu-privacy`), {
          duration: 0.5,
          opacity: 0,
        });
        gsap.timeline({ delay: 0 }).to(q(`#menu-button`), {
          duration: 0.5,
          opacity: 0,
        });
        gsap.timeline({ delay: 0 }).to(q(`#menu-details`), {
          duration: 0.5,
          opacity: 0,
        });
        gsap.timeline({ delay: 0 }).to(q(`#menu-image`), {
          duration: 0.4,
          opacity: 0,
          transform: 'translateY(100px)',
        });
        [5, 4, 3, 2, 1].forEach((item, index) => {
          gsap.timeline({ delay: index * 0.1 }).to(q(`#menu-item-${item}`), {
            duration: 0.4,
            opacity: 0,
            transform: 'translateX(-30px)',
          });
        });

        gsap
          .timeline({ delay: 0.8 })
          .to(q(`#menu-modal`), { duration: 0.4, transform: 'translateX(100vw)', opacity: 0.9 });
      } else {
        setVisible(prev => !prev);
        document.body.classList.add('body-overflow-hidden');
        const q = gsap.utils.selector(el);
        gsap.timeline().to(q(`#menu-modal`), { duration: 0.4, transform: 'none', opacity: 1 });

        [1, 2, 3, 4, 5, 6].forEach((item, index) => {
          gsap
            .timeline({ delay: index * 0.1 })
            .to(q(`#menu-item-${item}`), { duration: 0.4, opacity: 1, transform: 'none' });
        });
        gsap.timeline({ delay: 0.6 }).to(q(`#menu-image`), {
          duration: 0.5,
          opacity: 1,
          transform: 'translateY(0)',
        });

        gsap.timeline({ delay: 0.8 }).to(q(`#menu-privacy`), {
          duration: 0.5,
          opacity: 1,
        });
        gsap.timeline({ delay: 0.8 }).to(q(`#lang-switch`), {
          duration: 0.5,
          opacity: 1,
        });
        gsap.timeline({ delay: 0.8 }).to(q(`#menu-button`), {
          duration: 0.5,
          opacity: 1,
        });
        gsap.timeline({ delay: 0.8 }).to(q(`#menu-details`), {
          duration: 0.5,
          opacity: 1,
        });
      }
      return !visible;
    };

    const handleKeyPress = (event: IAnyType) => {
      if (event.keyCode === 27 && visible) {
        toggleMenu();
      }
    };

    useEffect(() => {
      window.addEventListener('keydown', handleKeyPress);
      return () => window.removeEventListener('keydown', handleKeyPress);
    }, [visible]);

    useImperativeHandle(ref, () => {
      return { toggleMenu };
    });

    const renderMenu = () => (
      <ul className="pt-24 lg:pt-32">
        {Array.isArray(menu) &&
          menu.length &&
          menu?.map((item: any, index: number) => (
            <li
              key={item.link}
              id={`menu-item-${index + 1}`}
              className="-translate-x-[-30px] opacity-0">
              <Link
                className="mb-4 block text-3xl transition-all hover:text-primary lg:mb-4"
                href={item.link}
                onClick={toggleMenu}>
                {item.title}
              </Link>
            </li>
          ))}
      </ul>
    );

    const renderPrivacyMenu = () => (
      <ul id="menu-privacy" className="hidden opacity-0 lg:block">
        {Array.isArray(menuPrivacy) &&
          menuPrivacy.length &&
          menuPrivacy?.map((item: any) => (
            <li key={item.link}>
              <Link
                className="mb-2 inline-block transition-all hover:text-primary"
                href={item.link}
                onClick={toggleMenu}>
                {item.title}
              </Link>
            </li>
          ))}
      </ul>
    );

    const renderDetails = () => (
      <div
        id="menu-details"
        className="overflow-[scroll!important] flex justify-center opacity-0 lg:absolute lg:bottom-2 lg:right-0 lg:h-screen lg:items-end">
        <div className="text-center lg:text-right">
          <div>{COMPANY_VARIABLES.address.city}</div>
          <div className="text-normal mb-10">
            {COMPANY_VARIABLES.address.street} <br />
            {COMPANY_VARIABLES.address.zipCode} {COMPANY_VARIABLES.address.city} <br />
            {COMPANY_VARIABLES.address.country}
          </div>

          <div className="flex flex-col items-center justify-center pb-8 lg:flex-row lg:justify-end lg:pb-2">
            <a
              href="https://www.designrush.com/agency/profile/nordit"
              target="_blank"
              rel="noopener noreferrer">
              <Image
                className="mb-4 h-[40px] w-[40px] lg:mb-0 lg:mr-4"
                height="80"
                width="80"
                src={designRushLogo}
                alt="Nordit - software development"
              />
            </a>
            <div className="flex items-center">
              <a
                aria-label="Nordit - LinkedIn"
                className="mr-1 cursor-pointer"
                href={COMPANY_VARIABLES.social.linkedin}
                target="_blank"
                rel="noopener noreferrer">
                <FaLinkedin
                  className=" fill-white transition-all duration-300 hover:fill-primary"
                  size={28}
                />
              </a>
              <a
                aria-label="Nordit - Instagram"
                className="mr-1 cursor-pointer"
                href={COMPANY_VARIABLES.social.instagram}
                target="_blank"
                rel="noopener noreferrer">
                <FaInstagramSquare
                  className=" fill-white transition-all duration-300 hover:fill-primary"
                  fill="black"
                  size={32}
                />
              </a>
              <a
                aria-label="Nordit - Facebook"
                className="mr-1 cursor-pointer"
                href={COMPANY_VARIABLES.social.facebook}
                target="_blank"
                rel="noopener noreferrer">
                <FaFacebookSquare
                  className=" fill-white transition-all duration-300 hover:fill-primary"
                  fill="white"
                  size={28}
                />
              </a>
              <a
                aria-label="Nordit - Twitter"
                className="cursor-pointer"
                href={COMPANY_VARIABLES.social.twitter}
                target="_blank"
                rel="noopener noreferrer">
                <FaSquareXTwitter
                  className=" fill-white transition-all duration-300 hover:fill-primary"
                  fill="white"
                  size={28}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );

    const renderImageFullScreen = () => (
      <div
        id="menu-image"
        className="absolute bottom-4 right-4 top-4 hidden max-h-[calc(100vh-20px)] w-[45vw] translate-y-[100px] overflow-hidden opacity-0 lg:block">
        <Image
          className="h-full w-full object-cover object-left-top"
          alt="Nordit - Rijeka"
          height={1200}
          src="https://firebasestorage.googleapis.com/v0/b/nordit-83a0b.appspot.com/o/rijekaIllustrationWhite.png?alt=media&token=e87d32b4-295f-4892-abe5-f46bb8e6eb87"
          width={2000}
        />
      </div>
    );

    const renderImageSmallScreen = () => (
      <div className="block overflow-hidden lg:hidden">
        <Image
          className="h-auto w-full object-cover"
          alt="Nordit - Rijeka"
          height={1200}
          src="https://firebasestorage.googleapis.com/v0/b/nordit-83a0b.appspot.com/o/rijekaIllustrationWhite.png?alt=media&token=e87d32b4-295f-4892-abe5-f46bb8e6eb87"
          width={2000}
        />
      </div>
    );

    return (
      <div ref={el}>
        <div
          id="menu-modal"
          className="bg-gradient fixed bottom-0 right-0 top-0 z-[9999] w-[100vw] translate-x-full overflow-scroll overflow-x-hidden opacity-90">
          <div className="container relative lg:h-full">
            <div className="grid lg:h-full lg:grid-cols-2">
              <div className="relative h-screen lg:h-full">
                {renderMenu()}

                <hr className="my-8 h-px w-20 border-white/50" />

                {otherLanguageSlug && (
                  <div id="lang-switch" className="mb-8 block opacity-0 lg:hidden">
                    <a className="py-4 pr-4" href={otherLanguageSlug}>
                      {otherLanguage}
                    </a>
                  </div>
                )}
                <Button
                  id="menu-button"
                  className="mb-8 w-[220px] text-black opacity-0 lg:hidden"
                  href={t('common:contactSlug')}>
                  {t('common:contactUs')}
                </Button>

                {renderImageSmallScreen()}
                {renderPrivacyMenu()}
                {otherLanguageSlug && (
                  <div className="hidden pt-4 lg:block">
                    <a className="py-4 pr-4" href={otherLanguageSlug}>
                      {otherLanguage}
                    </a>
                  </div>
                )}

                <div
                  id="menu-button"
                  className="mb-4 hidden justify-center opacity-0 lg:mb-0 lg:flex lg:justify-start">
                  <Button className="mt-7 text-black" href={t('common:contactSlug')}>
                    {t('contactUs')}
                  </Button>
                </div>

                {renderDetails()}
              </div>
            </div>
          </div>
          {renderImageFullScreen()}
        </div>
      </div>
    );
  },
);
