import { link } from 'fs';

export const COMPANY_VARIABLES = {
  name: 'Nordit',
  address: {
    city: 'Rijeka',
    street: 'Slavka Krautzeka',
    zipCode: '51000',
    country: 'Hrvatska',
  },
  email: 'info@nordit.co',
  social: {
    facebook: 'https://www.facebook.com/nordit.software.development',
    instagram: 'https://www.instagram.com/nordit.software.development',
    linkedin: 'https://www.linkedin.com/company/nordit-software-development',
    twitter: 'https://x.com/NorditSoftware',
  },
};
