import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { COMPANY_VARIABLES } from 'common/constants/company';

export const Footer: React.FC<{ borderTop?: boolean }> = ({ borderTop = true }) => {
  const { t } = useTranslation('common');

  return (
    <footer className="bg-gradient">
      <div
        className={clsx('container py-10 md:py-24', {
          'border-t-[1px] border-t-gray-200/10': borderTop,
        })}>
        <div className="grid items-end lg:grid-cols-2">
          <div className="lg:pb-20">
            <div className="hidden text-white lg:block lg:max-w-[280px]">{t('letUsTalk')}</div>
            <Link
              className="flex items-center justify-center text-white lg:justify-start"
              href="mailto:info@nordit.co">
              <img
                alt=""
                className="mr-2 mt-[1px] h-8 w-8 object-contain"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzQyMF80MjA2IiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IiNEOUQ5RDkiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzQyMF80MjA2KSI+CjxwYXRoIGQ9Ik00LjMgMTkuNUMzLjggMTkuNSAzLjM3NSAxOS4zMjUgMy4wMjUgMTguOTc1QzIuNjc1IDE4LjYyNSAyLjUgMTguMiAyLjUgMTcuN1Y2LjNDMi41IDUuOCAyLjY3NSA1LjM3NSAzLjAyNSA1LjAyNUMzLjM3NSA0LjY3NSAzLjggNC41IDQuMyA0LjVIMTkuN0MyMC4yIDQuNSAyMC42MjUgNC42NzUgMjAuOTc1IDUuMDI1QzIxLjMyNSA1LjM3NSAyMS41IDUuOCAyMS41IDYuM1YxNy43QzIxLjUgMTguMiAyMS4zMjUgMTguNjI1IDIwLjk3NSAxOC45NzVDMjAuNjI1IDE5LjMyNSAyMC4yIDE5LjUgMTkuNyAxOS41SDQuM1pNMTIgMTIuNTVMNCA3LjQ1VjE3LjdDNCAxNy43ODMzIDQuMDI5MzMgMTcuODU0MyA0LjA4OCAxNy45MTNDNC4xNDYgMTcuOTcxIDQuMjE2NjcgMTggNC4zIDE4SDE5LjdDMTkuNzgzMyAxOCAxOS44NTQzIDE3Ljk3MSAxOS45MTMgMTcuOTEzQzE5Ljk3MSAxNy44NTQzIDIwIDE3Ljc4MzMgMjAgMTcuN1Y3LjQ1TDEyIDEyLjU1Wk0xMiAxMUwxOS44NSA2SDQuMTVMMTIgMTFaTTQgNy40NVY2VjE3LjdDNCAxNy43ODMzIDQuMDI5MzMgMTcuODU0MyA0LjA4OCAxNy45MTNDNC4xNDYgMTcuOTcxIDQuMjE2NjcgMTggNC4zIDE4SDRWMTcuN1Y3LjQ1WiIgZmlsbD0iI0YyQzMwMSIvPgo8L2c+Cjwvc3ZnPgo="
              />
              {COMPANY_VARIABLES.email}
            </Link>
          </div>

          <div className="flex items-center justify-center lg:flex lg:flex-col lg:items-end lg:text-right">
            <Image
              id="header-logo"
              className="relative z-30 mb-8 mt-8 w-[130px] lg:mt-0"
              src="https://firebasestorage.googleapis.com/v0/b/nordit-83a0b.appspot.com/o/logo%2FNordit%20logo%20-%20primary%20white%2FNordit%20logo%20-%20primary%20white.svg?alt=media&token=53c70a77-ccd9-4fc6-8019-47aba940c62f"
              alt="Nordit software development"
              width={412}
              height={70}
            />

            <div className="hidden text-white lg:block lg:max-w-[280px]">
              {t('weCollaborateWithAmbitiousCompanies')}
            </div>
          </div>
        </div>

        <div className="grid items-end lg:grid-cols-3">
          <div>
            <div className="mb-3 hidden text-center font-bold lg:block lg:text-left">
              {t('ourLocation')}
            </div>
            <div className="mb-2 text-center text-white lg:text-left">
              {COMPANY_VARIABLES.address.city}
            </div>
            <div className="text-center leading-6 text-white/90 lg:text-left">
              {COMPANY_VARIABLES.address.street}
              <br />
              {COMPANY_VARIABLES.address.zipCode} {COMPANY_VARIABLES.address.city}
              <br />
              {COMPANY_VARIABLES.address.country}
            </div>
          </div>

          <nav className="mt-4 text-left lg:text-center">
            <ul className="flex justify-center gap-x-3 lg:justify-center">
              <li className="relative">
                <Link
                  className="after:content-[' '] pr-4 text-lg text-white/90 after:absolute after:right-0 after:top-4 after:h-[4px] after:w-[4px] after:rounded-full after:bg-primary"
                  href={t('common:termsAncConditionsSlug')}>
                  {t('termsAndConditions')}
                </Link>
              </li>

              <li>
                <Link className="text-lg text-white/90" href={t('common:privacyPolicySlug')}>
                  {t('privacyPolicy')}
                </Link>
              </li>
            </ul>
          </nav>

          <div className="mt-4 flex items-center justify-center lg:justify-end">
            <div className="mr-4 hidden h-px w-24 bg-primary lg:block"></div>

            <a
              aria-label="Nordit - LinkedIn"
              className="mr-1 cursor-pointer"
              href={COMPANY_VARIABLES.social.linkedin}
              target="_blank"
              rel="noopener noreferrer">
              <FaLinkedin
                className=" fill-white transition-all duration-300 hover:fill-primary"
                size={32}
              />
            </a>
            <a
              aria-label="Nordit - Instagram"
              className="mr-1 cursor-pointer"
              href={COMPANY_VARIABLES.social.instagram}
              target="_blank"
              rel="noopener noreferrer">
              <FaInstagramSquare
                className=" fill-white transition-all duration-300 hover:fill-primary"
                fill="black"
                size={32}
              />
            </a>
            <a
              aria-label="Nordit - Facebook"
              className="mr-1 cursor-pointer"
              href={COMPANY_VARIABLES.social.facebook}
              target="_blank"
              rel="noopener noreferrer">
              <FaFacebookSquare
                className=" fill-white transition-all duration-300 hover:fill-primary"
                fill="black"
                size={32}
              />
            </a>
            <a
              aria-label="Nordit - Twitter"
              className="cursor-pointer"
              href={COMPANY_VARIABLES.social.twitter}
              target="_blank"
              rel="noopener noreferrer">
              <FaSquareXTwitter
                className=" fill-white transition-all duration-300 hover:fill-primary"
                fill="black"
                size={32}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
